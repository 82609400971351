//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCImage from '~/components/FCImage.vue';

export default {
  name: 'CommunityTabletUpView',
  components: {
    FCImage,
  },
  props: {
    communityList: {
      type: Array,
      required: true,
      validator(data) {
        return data.every(entry => entry.src && entry.alt && entry.instagramHandle);
      },
    },
  },
  methods: {
    hideImageContainer(imageIndex) {
      return imageIndex === 3 || imageIndex === 4 ? 'md:fc-hidden' : '';
    },
  },
};
